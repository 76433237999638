import {configureStore} from '@reduxjs/toolkit';

import {CourseReducer} from './course/reducer';

const store = configureStore({
    reducer: {
        course: CourseReducer,
    }
});

export default store;
