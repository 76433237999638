import CourseActionTypes from './types';
const ZILTER_API_DOMAIN = 'https://api.qa.dts.auth.zilter.io'

export const coursesGetRequest = (data, page, signal, handleSuccess, handleError) => {
    return async (dispatch) => {
        dispatch({
            type: CourseActionTypes.GET_ALL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('token', '');
        const response = await fetch(`${ZILTER_API_DOMAIN}/courses/get?page=${page}&pageSize=10`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data ? data : {}),
            signal
        }).catch((error) => {
            // Your error is here!
            console.log(error)
            return false;
        });
        if(response) {
            const body = await response?.json();
            const {response: status, body: result} = {response, body};
            if (!status?.ok) {
                dispatch({
                    type: CourseActionTypes.GET_ALL_ERROR
                });
                handleError && handleError(result)
            } else {
                dispatch({
                    type: CourseActionTypes.GET_ALL_SUCCESS,
                    payload: result
                });
                handleSuccess && handleSuccess(result)
            }
        }
    };
};

export const initCourseRequest = (data, page, handleSuccess, handleError) => {
    return async (dispatch) => {
        dispatch({
            type: CourseActionTypes.INIT_ALL_COURSES
        });
    };
};

export const coursesGetRequest1 = (url, page, handleSuccess, handleError) => {
    return async (dispatch) => {
        dispatch({
            type: CourseActionTypes.GET_ALL_REQUEST
        });
        const response = await fetch(`${ZILTER_API_DOMAIN}/courses/open?page=${page}&pageSize=10&country=australia`, {
            method: 'GET'
        });
        const body = await response.json();
        const {response: status, body: result} = {response, body};
        if (!status.ok) {
            dispatch({
                type: CourseActionTypes.GET_ALL_ERROR
            });
            handleError && handleError(result)
        } else {
            dispatch({
                type: CourseActionTypes.GET_ALL_SUCCESS,
                payload: result
            });
            handleSuccess && handleSuccess(result)
        }
    };
};

export const universitiesGetRequest = (data, page, limit, handleSuccess, handleError) => {
    return async (dispatch) => {
        dispatch({
            type: CourseActionTypes.GET_ALL_UNIVERSITIES_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('token', '');
        const response = await fetch(`${ZILTER_API_DOMAIN}/universities/get?page=${page}&pageSize=${limit}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(data ? data : {})
        });
        const body = await response.json();
        const {response: status, body: result} = {response, body};
        if (!status.ok) {
            dispatch({
                type: CourseActionTypes.GET_ALL_UNIVERSITIES_ERROR
            });
            handleError && handleError(result)
        } else {
            dispatch({
                type: CourseActionTypes.GET_ALL_UNIVERSITIES_SUCCESS,
                payload: result
            });
            handleSuccess && handleSuccess(result)
        }
    };
};

// export const filtersUniversitiesGetRequest1 = (handleSuccess, handleError) => {
//     return async (dispatch) => {
//         dispatch({
//             type: CourseActionTypes.GET_FILTER_UNIVERSITIES_REQUEST
//         });
//         const headers = new Headers();
//         headers.append('token', 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZXJpZnlzdHVkZW50c0B5b3BtYWlsLmNvbSIsInJvbGUiOiJiYWJlM2U0OC05MjlmLTQ2MWQtOWFkYy1jYjA0NjM0OTNhZWIiLCJ0ZW5hbnRfaWQiOiJiNDg3NmQ5MjQ1ZDM0YzgwYjIyYmE0ZGEzYzA0YmMxMSIsInVzZXJfaWQiOiIzZmUzMTg2YS1lMTc5LTRkZGEtYjBkZC01NTc3YTFmMzQxYjMiLCJjdXJyZW50X3N0YWdlIjoiOCIsImlhdCI6MTcyMzYwMDAyMSwiZXhwIjoxNzIzNjg2NDIxfQ.LQ3FjyNQM4lGmqRDlAwG8E0cBcXLn5fbEdcaKonWUQs');
//         const response = await fetch(`${ZILTER_API_DOMAIN}/universities?page=0&pageSize=500&country=Australia`, {
//             method: 'GET',
//             headers: headers
//         });
//         const body = await response.json();
//         const {response: status, body: result} = {response, body};
//         if (!status.ok) {
//             dispatch({
//                 type: CourseActionTypes.GET_FILTER_UNIVERSITIES_ERROR
//             });
//             handleError && handleError(result)
//         } else {
//             dispatch({
//                 type: CourseActionTypes.GET_FILTER_UNIVERSITIES_SUCCESS,
//                 payload: result
//             });
//             handleSuccess && handleSuccess(result)
//         }
//     };
// };

// export const filtersUniversitiesGetRequest = (handleSuccess, handleError) => {
//     return async (dispatch) => {
//         dispatch({
//             type: CourseActionTypes.GET_FILTER_UNIVERSITIES_REQUEST
//         });
//         const headers = new Headers();
//         headers.append('token', 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ2ZXJpZnlzdHVkZW50c0B5b3BtYWlsLmNvbSIsInJvbGUiOiJiYWJlM2U0OC05MjlmLTQ2MWQtOWFkYy1jYjA0NjM0OTNhZWIiLCJ0ZW5hbnRfaWQiOiJiNDg3NmQ5MjQ1ZDM0YzgwYjIyYmE0ZGEzYzA0YmMxMSIsInVzZXJfaWQiOiIzZmUzMTg2YS1lMTc5LTRkZGEtYjBkZC01NTc3YTFmMzQxYjMiLCJjdXJyZW50X3N0YWdlIjoiOCIsImlhdCI6MTcyMzYwMDAyMSwiZXhwIjoxNzIzNjg2NDIxfQ.LQ3FjyNQM4lGmqRDlAwG8E0cBcXLn5fbEdcaKonWUQs');
//         const response = await fetch(`${ZILTER_API_DOMAIN}/universities?page=0&pageSize=500&country=Australia`, {
//             method: 'GET',
//             headers: headers
//         });
//         const body = await response.json();
//         const {response: status, body: result} = {response, body};
//         if (!status.ok) {
//             dispatch({
//                 type: CourseActionTypes.GET_FILTER_UNIVERSITIES_ERROR
//             });
//             handleError && handleError(result)
//         } else {
//             dispatch({
//                 type: CourseActionTypes.GET_FILTER_UNIVERSITIES_SUCCESS,
//                 payload: result
//             });
//             handleSuccess && handleSuccess(result)
//         }
//     };
// };

export const filtersDepartmentsGetRequest = (handleSuccess, handleError) => {
    return async (dispatch) => {
        dispatch({
            type: CourseActionTypes.GET_FILTER_DEPARTMENTS_REQUEST
        });
        const response = await fetch(`${ZILTER_API_DOMAIN}/universities?filters=university_name&pageSize=500&`, {
            method: 'GET'
        });
        const body = await response.json();
        const {response: status, body: result} = {response, body};
        if (!status.ok) {
            dispatch({
                type: CourseActionTypes.GET_FILTER_DEPARTMENTS_ERROR
            });
            handleError && handleError(result)
        } else {
            dispatch({
                type: CourseActionTypes.GET_FILTER_DEPARTMENTS_SUCCESS,
                payload: result
            });
            handleSuccess && handleSuccess(result)
        }
    };
};