import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { XMarkIcon, ArrowDownCircleIcon } from '@heroicons/react/24/solid'
import './App.css';
import List from './finder/List';
import ListSelected from './finder/ListSelected';
import Overview from './finder/Overview';
import { Dropdown } from "flowbite-react";
import auJson from './utils/au.json';
import departmentsJson from './utils/departments.json';
import { coursesGetRequest, universitiesGetRequest, initCourseRequest } from './app/course/action';



const SORTING = [{
	id: 'course_fee',
	order: 'asc',
	label: 'Lowest Fee',
},{
	id: 'institute_rank',
	order: 'desc',
	label: 'Highest Rank',
}]

const MONTHS = [{
	id: 'january',
	label: 'January',
},{
	id: 'february',
	label: 'February',
},{
	id: 'march',
	label: 'March',
},{
	id: 'april',
	label: 'April',
},{
	id: 'may',
	label: 'May',
},{
	id: 'june',
	label: 'June',
},{
	id: 'july',
	label: 'July',
},{
	id: 'august',
	label: 'August',
},{
	id: 'september',
	label: 'September',
},{
	id: 'october',
	label: 'October',
},{
	id: 'november',
	label: 'November',
},{
	id: 'december',
	label: 'December',
}]

const LEVELS = [{
	id: "1",
	label: 'Undergraduate',
},{
	id: "2",
	label: 'Postgraduate',
},{
	id: "3",
	label: 'Foundation',
},{
	id: "4",
	label: 'Research',
}]

const customTheme = {
    floating: {
      base: 'max-w-72 max-h-48 overflow-y-auto z-10 rounded divide-y divide-gray-100 shadow-lg',
    }
};

const isJson = (str) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}

function App() {
	const coursesResponse = useSelector((state) => state.course.courses)
	const coursePagination = useSelector((state) => state.course.coursePagination)
	const universitiesResponse = useSelector((state) => state.course.universities)
	// const departments = useSelector((state) => state.course.departments)
	const loading = useSelector((state) => state.course.loading)
	const dispatch = useDispatch()
	const [mode, setMode] = useState(false)
	const [filters, setFilters] = useState([])
	const [sorts, setSorts] = useState([])
	const [course, setCourse] = useState(null)
	const [courses, setCourses] = useState([])
	const [universities, setUniversities] = useState([])
	const [filteredUniversities, setFilteredUniversities] = useState([])
	const [cities, setCities] = useState(auJson)
	const [departments, setDepartments] = useState(departmentsJson)
	const [design, setDesign] = useState('rounded')
	const [bookingLink, setBookingLink] = useState('')
	const [bookingLinkText, setBookingLinkText] = useState('Book a meeting')
	const [color, setColor] = useState('#bc232b')
	const [page, setPage] = useState(0);
	const [pagination, setPagination] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [search, setSearch] = useState('');
	const observer = useRef();

	useEffect(() => {
		if(universitiesResponse) {
			const universitiesObj = universitiesResponse.map(university => {
				const universityJson = isJson(university.valuesJson)
				return {...universityJson, id: university.id}
			})
			// const ausUniversities = universitiesObj.filter(university => university.country == 'australia')
			setUniversities(universitiesObj)
			setFilteredUniversities(universitiesObj)
		}
	}, [universitiesResponse])

	useEffect(() => {
		const sortsObj = [
			{
			  "field": "institute_name",
			  "order": "asc"
			}
		]
		const filtersObj = [
			{
			  "quick": [],
			  "advance": [
				{
				  "filters": [
					{
					  "key": "country",
					  "condition": "IN",
					  "values": [
						"australia"
					  ]
					}
				  ]
				}
			  ],
			  "search": {}
			}
		]
		dispatch(universitiesGetRequest({filters: filtersObj, sorts: sortsObj}, 0, 500))
	}, [])

	useEffect(() => {
		if(coursePagination) {
			setPage(coursePagination.page)
			setHasMore(!coursePagination.last)
		}
	}, [coursePagination])

	useEffect(() => {
		if(coursesResponse?.length) {
			if(pagination) {
				setPagination(false)
				setCourses((prevCourses) => [...prevCourses, ...coursesResponse]);
			} else {
				setCourses(coursesResponse)
			}
		}
	}, [coursesResponse])
	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;
	
		// Clear timer and cancel request on unmount
		const cleanup = () => {
			controller.abort("New request");
			clearTimeout(timer);
		};
	
		// Main logic wrapped in a timer
		const timer = setTimeout(() => {
			if (coursePagination?.page === page || page === 0) {
				setCourses([]);
				dispatch(initCourseRequest());
			}

	
			// Construct filter and sort objects
			const sortsObj = []

			const filtersObj = [{
				"quick": [],
				"advance": [
					{
						"filters": [
							{
								"key": "country",
								"condition": "IN",
								"values": ["australia"]
							}
						]
					}
				],
				"search": {}
			}]
	
			// Build filter objects based on conditions
			const filterMapping = {
				university: { key: 'institute', condition: 'IN', values: [] },
				level: { key: 'level', condition: 'IN', values: [] },
				intake: { key: 'intake', condition: 'IN', values: [] },
				cities: { key: 'location', condition: 'IN', values: [] },
				departments: { key: 'department_name', condition: 'IN', values: [] },
			};
	
			filters?.forEach(filter => {
				if (filterMapping[filter.model]) {
					filterMapping[filter.model].values.push(filter.id);
				}
			});
	
			// Add filters if they have values
			Object.values(filterMapping).forEach(filter => {
				if (filter.values.length) {
					filtersObj[0].advance[0].filters.push(filter);
				}
			});
	
			// Add search filter
			if (search) {
				filtersObj[0].advance[0].filters.push({
					key: 'course_name',
					condition: 'LIKE',
					values: [search],
				});
			}

			if(sorts?.length) {
				// Build sort object
				sorts?.forEach(sort => {
					sortsObj.push({
						field: sort.id,
						order: sort.order,
					});
				});
			} else {
				// Default sort object
				sortsObj.push({
					"field": "course_name",
					"order": "asc"
				});
			}
	
			// Dispatch request with filters, sorts, and signal for aborting
			dispatch(coursesGetRequest({ filters: filtersObj, sorts: sortsObj }, page, signal));
		}, 1200);
	
		// Cleanup on unmount or effect rerun
		return cleanup;
	}, [filters, search, page, sorts]);

	useEffect(() => {
		if (window?.dark) {
			setMode(window?.dark)
		}
		if (window?.design) {
			setDesign(window?.design)
		}
		if (window?.booking_link) {
			setBookingLink(window?.booking_link)
		}
		if (window?.booking_link_text) {
			setBookingLinkText(window?.booking_link_text)
		}
		if (window?.color) {
			setColor(window?.color)
		}
	}, []);

	const onUniversitySearch = (e) => {
		e.preventDefault();
		const searchTerm = e.target.value.toLowerCase();
	
		const filteredUniversities = searchTerm
			? universities.filter(university =>
				university?.institute_name?.toLowerCase().includes(searchTerm)
			  )
			: universities;
	
		setFilteredUniversities(filteredUniversities);
	};

	const onCitySearch = (e) => {
		e.preventDefault();
		const searchTerm = e.target.value.toLowerCase();
	
		const filteredCities = searchTerm
			? auJson.filter(city =>
				city?.city?.toLowerCase().includes(searchTerm)
			  )
			: auJson;
	
		setCities(filteredCities);
	};

	const onDepartmentSearch = (e) => {
		e.preventDefault();
		const searchTerm = e.target.value.toLowerCase();
	
		const filteredDepartments = searchTerm
			? departmentsJson.filter(department =>
				department?.label?.toLowerCase().includes(searchTerm)
			  )
			: departmentsJson;
	
		setDepartments(filteredDepartments);
	};

	const onSearch = (e) => {
		setPage(0)
		setCourse(null)
		setSearch(e.target.value)
	}
	const onFiltersClicked = (e, newValue) => {
		setPage(0)
		setCourse(null)
		e?.preventDefault();
		e?.stopPropagation();
		if(filters.some(e => e.id === newValue.id)) {
			removeFilterClicked(newValue)
		} else {
			setFilters(oldFilters => [...oldFilters, newValue]);
		}
	}
	const onSortingClicked = (e, newValue) => {
		setPage(0)
		setCourse(null)
		e?.preventDefault();
		e?.stopPropagation();
		if(sorts.some(e => e.id === newValue.id)) {
			const sortedArray = filters.filter(e => e.id !== newValue.id)
			setSorts(sortedArray)
		} else {
			setSorts(oldSorts => [...oldSorts, newValue]);
		}
	}
	const removeFilterClicked = (value) => {
		setPage(0)
		setCourse(null)
		const filteredArray = filters.filter(e => e.id !== value.id)
		setFilters(filteredArray)
	}
	const onClickClearAll = () => {
		setPage(0)
		setCourse(null)
		setFilters([])
	}
	const lastElementRef = useCallback(
		(node) => {
			if (loading || !hasMore) return; // Stop observing if loading or no more posts
			if (observer.current) observer.current.disconnect();

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setPagination(true)
					setPage((prevPage) => prevPage + 1); // Trigger loading of new posts by changing page number
				}
			});

			if (node) observer.current.observe(node);
		},
		[loading, hasMore]
	);
	const count = {
		university: 0,
		level: 0,
		intake: 0,
		cities: 0,
		departments: 0,
	};
	
	// Using forEach to iterate through the filters and directly increment the respective counts
	filters.forEach(({ model }) => {
	if (count.hasOwnProperty(model)) {
		count[model]++;
	}
	});
	return (
		<div className={mode ? 'dark' : 'light'}>
			<div className="flex justify-between py-4">
				<div className="flex gap-4">
					<Dropdown
						theme={customTheme}
						label="Sort"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								Sort
								<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						{SORTING?.map((sort, sortKey) => {
							return (
								<Dropdown.Item key={sortKey} title={sort.label}>
									{ sorts.some(e => e.id === sort.id) ? <input onClick={(e) => onSortingClicked(e, {id: sort.id, label: sort.label, order: sort.order})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onSortingClicked(e, {id: sort.id, label: sort.label, order: sort.order})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onSortingClicked(e, {id: sort.id, label: sort.label, order: sort.order})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{sort.label}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
				</div>
				<div className="flex gap-1 divide-x divide-solid divide-slate-300">
					<form className="flex items-center max-w-sm mx-auto px-5 py-0">   
						<label htmlFor="simple-search" className="sr-only">Search</label>
						<div className="relative w-full">
							<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
								</svg>
							</div>
							<input onChange={(e) => onSearch(e)} value={search} type="text" id="simple-search" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search course..." />
						</div>
					</form>
					<Dropdown
						theme={customTheme}
						label="University"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								University
								{
									count.university ?
									<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.university}</span>
									: ''
								}
								<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						<div className="p-3">
							<label htmlFor="input-group-search" className="sr-only">Search universities</label>
							<div className="relative">
								<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
									<path stroke="currentColor" strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
								</svg>
								</div>
								<input onKeyDown={(e) => e.stopPropagation()} onChange={(e) => onUniversitySearch(e)} type="text" id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search universities" />
							</div>
						</div>
						{filteredUniversities?.map((university, universityKey) => {
							return (
								<Dropdown.Item key={universityKey} title={university.institute_name}>
									{ filters.some(e => e.id === university.id) ? <input onClick={(e) => onFiltersClicked(e, {id: university.id, label: university.institute_name, model: 'university'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onFiltersClicked(e, {id: university.id, label: university.institute_name, model: 'university'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onFiltersClicked(e, {id: university.id, label: university.institute_name, model: 'university'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{university.institute_name}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
					<Dropdown
						theme={customTheme}
						label="Level"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								Level
								{
									count.level ?
									<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.level}</span>
									: ''
								}
								<svg className="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						{LEVELS?.map((level, levelKey) => {
							return (
								<Dropdown.Item key={levelKey} title={level.label}>
									{ filters.some(e => e.id === level.id) ? <input onClick={(e) => onFiltersClicked(e, {id: level.id, label: level.label, model: 'level'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onFiltersClicked(e, {id: level.id, label: level.label, model: 'level'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onFiltersClicked(e, {id: level.id, label: level.label, model: 'level'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{level.label}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
					<Dropdown
						theme={customTheme}
						label="Department"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								Department
								{
									count.departments ?
									<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.departments}</span>
									: ''
								}
								<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						<div className="p-3">
							<label htmlFor="input-group-search" className="sr-only">Search departments</label>
							<div className="relative">
								<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
									<path stroke="currentColor" strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
								</svg>
								</div>
								<input onKeyDown={(e) => e.stopPropagation()} onChange={(e) => onDepartmentSearch(e)} type="text" id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search departments" />
							</div>
						</div>
						{departments?.map((department, departmentKey) => {
							return (
								<Dropdown.Item key={departmentKey} title={department.label}>
									{ filters.some(e => e.id === department.id) ? <input onClick={(e) => onFiltersClicked(e, {id: department.id, label: department.label, model: 'departments'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onFiltersClicked(e, {id: department.id, label: department.label, model: 'departments'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onFiltersClicked(e, {id: department.id, label: department.label, model: 'departments'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{department.label}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
					<Dropdown
						theme={customTheme}
						label="City"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								City
								{
									count.city ?
									<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.city}</span>
									: ''
								}
								<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						<div className="p-3">
							<label htmlFor="input-group-search" className="sr-only">Search cities</label>
							<div className="relative">
								<div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
								<svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
									<path stroke="currentColor" strokeLinecap="round" strokeLineJoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
								</svg>
								</div>
								<input onKeyDown={(e) => e.stopPropagation()} onChange={(e) => onCitySearch(e)} type="text" id="input-group-search" className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search cities" />
							</div>
						</div>
						{cities?.map((city, cityKey) => {
							return (
								<Dropdown.Item key={cityKey} title={city.city}>
									{ filters.some(e => e.id === city.city) ? <input onClick={(e) => onFiltersClicked(e, {id: city.city, label: city.city, model: 'cities'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onFiltersClicked(e, {id: city.city, label: city.city, model: 'cities'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onFiltersClicked(e, {id: city.city, label: city.city, model: 'cities'})} htmlFor="checkbox-item-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate">{city.city}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
					<Dropdown
						theme={customTheme}
						label="Intake"
						dismissOnClick={false}
						renderTrigger={() => 
							<span className="gap-2 cursor-pointer text-slate-900 hover:text-slate-600 font-medium text-sm px-5 py-0 text-center inline-flex items-center">
								Intake
								{
									count.intake ?
									<span className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{count.intake}</span>
									: ''
								}
								<svg className="w-2.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
									<path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
								</svg>
							</span>
						}>
						{MONTHS?.map((intake, intakeKey) => {
							return (
								<Dropdown.Item key={intakeKey} title={intake.label}>
									{ filters.some(e => e.id === intake.id) ? <input onClick={(e) => onFiltersClicked(e, {id: intake.id, label: intake.label, model: 'intake'})} checked={true} id="checked-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-blue-600 border-blue-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input> :
									 <input onClick={(e) => onFiltersClicked(e, {id: intake.id, label: intake.label, model: 'intake'})} id="checkbox-item-1" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500 cursor-pointer"></input>}
									<label onClick={(e) => onFiltersClicked(e, {id: intake.id, label: intake.label, model: 'intake'})} htmlFor="checkbox-item-1" className="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer truncate text-left">{intake.label}</label>
								</Dropdown.Item>
							)
						})}
					</Dropdown>
				</div>
			</div>
			<div className="flex bg-slate-200 items-center justify-between px-2 py-4 w-full divide-x divide-solid divide-slate-300">
				<p className="flex align-center justify-center text-md leading-6 font-medium text-slate-600 px-1 py-0 w-[70px]">Filter</p>
				<div className="flex flex-1 px-5 py-0 gap-2 overflow-y-auto whitespace-nowrap">
					{
						filters.map((filter, filterKey) => <button key={filterKey} className="flex shadow-sm items-center gap-2 rounded-full bg-white px-4 py-1 font-medium text-slate-600" onClick={() => removeFilterClicked(filter)}><label className="truncate max-w-40">{filter.label}</label> <XMarkIcon className="size-4 text-slate-600" /></button>)
					}
				</div>
				<div className="flex items-center justify-between w-[100px] ps-2">
					<button onClick={() => onClickClearAll()} className="bg-transparent px-1 py-1">Clear all</button>
				</div>
			</div>
			{
				course ?
					<div className="flex divide-x divide-solid">
						<div className="w-[500px] flex flex-col">
							<div className="w-full flex-col flex h-[85vh] overflow-scroll">
								<ListSelected
									courseSelected={course}
									courses={courses}
									setCourse={setCourse}
									coursesResponse={coursesResponse}
									loading={loading}
									lastElementRef={lastElementRef}
								/>
							</div>
						</div>
						<div className="flex w-full h-[85vh] overflow-scroll">
							<Overview course={course} />
						</div>
					</div> :
					<div className="flex flex-col w-full">
						<List courses={courses} setCourse={setCourse} loading={loading} onClickClearAll={onClickClearAll} />
						{!loading && coursesResponse.length ? hasMore ? <div ref={lastElementRef} className="flex items-center justify-center my-6">
							<ArrowDownCircleIcon className="size-16 animate-bounce text-gray-500 stroke-2" />
						</div> : <div ref={lastElementRef} className="flex items-center justify-center my-6">
							<p className="flex gap-1 text-md leading-6 text-gray-700">No more courses</p>
						</div> : null}
					</div>
			}
		</div>
	);
}

export default App;
