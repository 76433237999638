import CourseActionTypes from './types';

export const initialState = {
    controller: null,
    course: null,
    university: null,
    courses: [],
    coursePagination: null,
    errors: undefined,
    loading: true,
    universityLoading: false,
    universities: [],
    universitiesLoading: false,
    departments: [],
    departmentsLoading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CourseActionTypes.GET_ALL_REQUEST: {
            return {...state, loading: true, controller: action.payload};
        }
        case CourseActionTypes.GET_ALL_SUCCESS: {
            return {
                ...state,
                loading: false,
                courses: action.payload.content,
                coursePagination: {
                    page: action.payload.number,
                    pages: action.payload.totalPages,
                    size: action.payload.size,
                    total: action.payload.totalElements,
                    last: action.payload.last,
                }
            };
        }
        case CourseActionTypes.GET_ALL_ERROR: {
            return {...state, loading: false, errors: action.payload, courses: [], coursePagination: null};
        }
        case CourseActionTypes.INIT_ALL_COURSES: {
            return {
                ...state,
                loading: false,
                courses: []
            }
        }
        case CourseActionTypes.GET_ALL_UNIVERSITIES_REQUEST: {
            return {...state, universityLoading: true};
        }
        case CourseActionTypes.GET_ALL_UNIVERSITIES_SUCCESS: {
            return {
                ...state,
                universityLoading: false,
                universities: action.payload.content,
                universitiesPagination: {
                    page: action.payload.number,
                    pages: action.payload.totalPages,
                    size: action.payload.size,
                    total: action.payload.totalElements,
                    last: action.payload.last,
                }
            };
        }
        case CourseActionTypes.GET_ALL_UNIVERSITIES_ERROR: {
            return {...state, universityLoading: false, errors: action.payload, universities: [], universitiesPagination: null};
        }
        case CourseActionTypes.GET_UNIVERSITY_REQUEST: {
            return {...state, universityLoading: true};
        }
        case CourseActionTypes.GET_UNIVERSITY_SUCCESS: {
            return {
                ...state,
                universityLoading: false,
                university: action.payload,
            };
        }
        case CourseActionTypes.GET_UNIVERSITY_ERROR: {
            return {...state, universityLoading: false, errors: action.payload, university: null};
        }
        case CourseActionTypes.GET_FILTER_UNIVERSITIES_REQUEST: {
            return {...state, universitiesLoading: true};
        }
        case CourseActionTypes.GET_FILTER_UNIVERSITIES_SUCCESS: {
            return {
                ...state,
                universitiesLoading: false,
                universities: action.payload,
            };
        }
        case CourseActionTypes.GET_FILTER_UNIVERSITIES_ERROR: {
            return {...state, universitiesLoading: false, errors: action.payload, unversities: null};
        }
        case CourseActionTypes.GET_FILTER_DEPARTMENTS_REQUEST: {
            return {...state, departmentsLoading: true};
        }
        case CourseActionTypes.GET_FILTER_DEPARTMENTS_SUCCESS: {
            return {
                ...state,
                departmentsLoading: false,
                departments: action.payload,
            };
        }
        case CourseActionTypes.GET_FILTER_DEPARTMENTS_ERROR: {
            return {...state, departmentsLoading: false, errors: action.payload, departments: null};
        }
        default: {
            return state;
        }
    }
};

export {reducer as CourseReducer};
