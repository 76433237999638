const CourseActionTypes = {
    SET_NEW_CONTROLLER: '@@course/SET_NEW_CONTROLLER',
    GET_ALL_REQUEST: '@@course/GET_ALL_REQUEST',
    GET_ALL_SUCCESS: '@@course/GET_ALL_SUCCESS',
    GET_ALL_ERROR: '@@course/GET_ALL_ERROR',
    INIT_ALL_COURSES: '@@course/INIT_ALL_COURSES',
    GET_ALL_UNIVERSITIES_REQUEST: '@@course/GET_ALL_UNIVERSITIES_REQUEST',
    GET_ALL_UNIVERSITIES_SUCCESS: '@@course/GET_ALL_UNIVERSITIES_SUCCESS',
    GET_ALL_UNIVERSITIES_ERROR: '@@course/GET_ALL_UNIVERSITIES_ERROR',
    GET_UNIVERSITY_REQUEST: '@@course/GET_UNIVERSITY_REQUEST',
    GET_UNIVERSITY_SUCCESS: '@@course/GET_UNIVERSITY_SUCCESS',
    GET_UNIVERSITY_ERROR: '@@course/GET_UNIVERSITY_ERROR',
    GET_FILTER_UNIVERSITIES_REQUEST: '@@course/GET_FILTER_UNIVERSITIES_REQUEST',
    GET_FILTER_UNIVERSITIES_SUCCESS: '@@course/GET_FILTER_UNIVERSITIES_SUCCESS',
    GET_FILTER_UNIVERSITIES_ERROR: '@@course/GET_FILTER_UNIVERSITIES_ERROR',
    GET_FILTER_DEPARTMENTS_REQUEST: '@@course/GET_FILTER_DEPARTMENTS_REQUEST',
    GET_FILTER_DEPARTMENTS_SUCCESS: '@@course/GET_FILTER_DEPARTMENTS_SUCCESS',
    GET_FILTER_DEPARTMENTS_ERROR: '@@course/GET_FILTER_DEPARTMENTS_ERROR',
}

export default CourseActionTypes
